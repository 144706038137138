import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { LoginPage } from '../pages/login/login'
import { RegisterPage } from '../pages/login/register'
import { ForgotPasswordPage } from '../pages/login/forgot-password'
import { DashboardPage } from '../pages/Dashboard/dashboard'
import { InvitationPage } from '../pages/Dashboard/invitation/invitation'
import { InvitationForm } from '../pages/Dashboard/invitation/form'
import { OrganizationPage } from '../pages/Dashboard/organization/organization'
import { ProductBulkupload } from '../pages/product-bulkupload'

interface Props {
    
}

export const RoutesPage = (props:Props) => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoginPage />}></Route>
                    <Route path="/login" element={<LoginPage />}></Route>
                    <Route path="/register" element={<RegisterPage />}></Route>
                    <Route path="/forgot-password" element={<ForgotPasswordPage />}></Route>
                    <Route path="/dashboard" element={<DashboardPage />}>
                        <Route path="/dashboard/invitation" element={<InvitationPage />}></Route>
                        <Route path="/dashboard/invitation/form" element={<InvitationForm />}></Route>
                        <Route path="/dashboard/organization" element={<OrganizationPage />}></Route>
                    </Route>
                    <Route path='/products/bulkupload/:bu' Component={ProductBulkupload}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    )
}
