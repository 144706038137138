import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApisService } from '../../../app/core/apis.service';
import { dataDecrypt, encrypt } from '../../../app/utility/encryption-helper';
import { toast } from 'react-toastify';
import CustomModel from '../../../components/modal/custom-model';

export const InvitationForm = () => {
    const navigate = useNavigate()
    const [invitationData, setInvitationData] = useState<any>({
        company_name: "",
        email_id: "",
        contact: "",
    });
    const [formErrors, setFormErrors] = useState<any>({});
    const [invitationModal, setInvitationModal] = useState(false);
    const [invitationCode, setInvitationCode] = useState<any>('');
    const [modelActions, setModelActions] = useState<any>([
        {
            label: 'Ok',
            type: 'Success'
        }
    ]);


    const handleCancelButton = () => {
        navigate("/dashboard/invitation");
    }


    const handleInput = (e: any) => {
        const { name, value } = e.target;

        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const phonePattern = /^[0-9]{10}$/;
        setFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: '',
        }));
        if (name === 'email_id') {
            if (!emailPattern.test(value)) {
                setFormErrors((prevErrors: any) => ({
                    ...prevErrors,
                    [name]: 'Please enter a valid email address',
                }));
                setInvitationData((prevData: any) => ({
                    ...prevData,
                    [name]: "",
                }));
            } else {
                setInvitationData((prevData: any) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else if (name === 'contact') {
            if (!phonePattern.test(value) || value.length !== 10) {

                setFormErrors((prevErrors: any) => ({
                    ...prevErrors,
                    [name]: 'Please enter a valid phone number',
                }));
                setInvitationData((prevData: any) => ({
                    ...prevData,
                    [name]: "",
                }));
            }
            else {
                setInvitationData((prevData: any) => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else {
            setInvitationData((prevData: any) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const validateForm = () => {
        const errors: any = {};
        if (!invitationData.company_name) {
            errors.company_name = "Please enter company name";
        }

        if (!invitationData.email_id) {
            errors.email_id = "Please enter valid email";
        }
        if (!invitationData.contact) {
            errors.contact = "Please enter mobile number";
        }


        setFormErrors(errors);
        return Object.keys(errors).length === 0; // Return true if there are no errors
    };

    async function onclickCreateInvitation(): Promise<void> {
        const isValid: any = validateForm();
        if (isValid) {
           
            let preparedData = {
                invitee: {
                    'email_id': invitationData.email_id,
                    'contact': invitationData.contact,
                    'company_name': invitationData.company_name,
                    "relation": "NA",
                    "accepted_date_time": "",
                    "supplier": true,
                    "buyer": false,
                },
                inviter: {
                    invite_company: "Admin",
                    invite_company_name: "ADMIN",
                    contact_person: "Admin",
                    contact: "Admin",
                    business_relation: "admin",
                    url: "admin@gmail.com",
                    invited_date_time: "",
                    supplier: false,
                    buyer: false
                }
            };

            try {
                setInvitationModal(true);
                let encrytedData = encrypt(JSON.stringify(preparedData));
                console.log(encrytedData);

                const res = await ApisService.createInvitation('createInvitation', encrytedData);
                console.log('data ', res, res.data.data);
                const invitationData = JSON.parse(dataDecrypt(res.data.data));
                console.log(invitationData, "invitationData");

                if (invitationData.insertedId) {
                    toast.success("Invitation created successfully");
                    setInvitationModal(true);
                    setInvitationCode(invitationData['sms-message']);
                    // navigate('/dashboard/invitation');
                }
            } catch (error) {
                console.error('Error in transaction:', error);
            }
        }

    }

    const getModalStatus = (status: any) => {
        setInvitationModal(false);
        navigate('/dashboard/invitation');
    }

    const getInvitationInfo = (status: any) => {
        const invitationInfo = status;
        if (invitationInfo) {
            navigate('/dashboard/invitation');
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 text-start my-3">
                    <span className="text-color text-start my-2 text-color fs-5 custom-font-weight">
                        Add Invitation
                    </span>
                </div>
                <div className="rounded bg_white">
                    <div className="col-12 pt-2">
                        <div className="row">
                            <div className="col-md-6 my-2">
                                <label className="form-label d-flex justify-content-start">
                                    Name <span className="text-danger ms-2 fw-bold">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="company_name"
                                    placeholder="Name"
                                    onChange={(event) => {
                                        handleInput(event);
                                    }}
                                />
                                {formErrors.company_name && (
                                    <div className="text-danger text-start">{formErrors.company_name}</div>
                                )}
                            </div>
                            <div className="col-md-6 my-2">
                                <label className="form-label d-flex justify-content-start">
                                    Email <span className="text-danger ms-2 fw-bold">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="email_id"
                                    defaultValue={invitationData?.email}
                                    placeholder="Email"
                                    onChange={(event) => {
                                        handleInput(event);
                                    }}
                                />
                                {formErrors.email_id && (
                                    <div className="text-danger text-start">{formErrors.email_id}</div>
                                )}
                            </div>
                            <div className="col-md-6 my-2">
                                <label className="form-label d-flex justify-content-start">
                                    Mobile Number{" "}
                                    <span className="text-danger ms-2 fw-bold">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="contact"
                                    defaultValue={invitationData?.contact}
                                    placeholder="Mobile Number"
                                    onChange={(event) => {
                                        handleInput(event);
                                    }}
                                />
                                {formErrors.contact && (
                                    <div className="text-danger text-start">
                                        {formErrors.contact}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="d-flex justify-content-end my-2 me-2 mb-3">
                        <button
                            className="custom-bg-btn text-white border-0 px-4 py-1 mx-2"
                            onClick={onclickCreateInvitation}
                        >
                            Create
                        </button>
                        <button
                            className="custom-cancel-btn rounded border-0 px-4 py-1 "
                            onClick={handleCancelButton}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            {invitationModal &&
                <CustomModel ismodalShow={invitationModal} modalStatus={getModalStatus} actions={modelActions}
                    modalHeaderText={invitationCode} modalType={"invitation"} deleteStatus={''}
                    modalTitle={'Invitation Info'} invitationInfo={getInvitationInfo} />
            }
        </div>
    )
}
