import axios from 'axios';
import { AppConfig } from './../config';
export class ApisService {
    public static async getInvitationData(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}:${AppConfig.INVITATION_PORT}/api/v1/http-utils/grpc`, {
            endpoint: `invitation.${AppConfig.BASE_URL}:${AppConfig.INVITATION_PORT}`,
            method, body
        });
    }

    public static async getOrganizationData(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}:${AppConfig.ORGANIZATION_PORT}/api/v1/http-utils/grpc`, {
            endpoint: `organization.${AppConfig.HOST_URL}:${AppConfig.ORGANIZATION_PORT}`,
            method, body
        });
    }

    public static async productApi(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}:${AppConfig.HTTP_PORT}/api/v1/http-utils/grpc`, {
            endpoint: `products.${AppConfig.HOST_URL}`,
            method, body
        });
    }

    public static async createInvitation(method: string, body: string): Promise<any> {
        return axios.post(`${AppConfig.BASE_URL}:${AppConfig.INVITATION_PORT}/api/v1/http-utils/grpc`, {
            endpoint: `invitation.${AppConfig.HOST_URL}:${AppConfig.INVITATION_PORT}`,
            method, body
        });
    }
}