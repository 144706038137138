import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { InvitationPage } from '../pages/Dashboard/invitation/invitation'
import { InvitationForm } from '../pages/Dashboard/invitation/form'
import { OrganizationPage } from '../pages/Dashboard/organization/organization'

export const DashboardRoutes = () => {
  return (
    <div>
        <Routes>
            <Route path="/" element={<InvitationPage/>}></Route>
            <Route path="/invitation" element={<InvitationPage/>}></Route>
            <Route path="/invitation/form" element={<InvitationForm/>}></Route>
            <Route path="/organization" element={<OrganizationPage />}></Route>
        </Routes>
    </div>
  )
}
