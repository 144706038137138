import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  ismodalShow: any;
  modalStatus: any;
  modalHeaderText: any;
  modalTitle: any;
  modalType: any;
  deleteStatus: any;
  logoutStatus?: any;
  accessObjectsData?: any;
  invitationInfo?: any;
  actions?: []
}

function CustomModel(props: Props) {
  const [show, setShow] = useState(props.ismodalShow);
  const navigate = useNavigate();

  const handleClose = () => {
    if (props.modalStatus) {
      props.modalStatus(false);
    }
  };

  const handleModalConfirmation = () => {
    setShow(false);
    if (props.modalType === "logout") {
      localStorage.clear();
      navigate("/login");
      props.logoutStatus(true);
    } else if (props.modalType === "delete") {
      props.deleteStatus(true);
    } else if (props.modalType === "invitation") {
      props.invitationInfo(true);
    }
  };


  return (
    <>
      {show && (
        <div
          className={`modal fade ${show ? "show" : "false"} ${show ? "modal-custom " : ""
            } ${show ? "modal-show " : "modal-hide"}`}
          tab-index="-1"
          role="dialog"
        >
          <div className={`modal-dialog modal-dialog-centered ${props.modalTitle === "Privileges" ? "modal-lg " : ""}`}
            role="document"
          >
            <div className={`modal-content modal-bg `}>
              <div className="modal-header">
                <h5 className="text-white">{props.modalTitle}</h5>
                <button
                  type="button"
                  className="btn-close bg-white"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div
                className={`modal-body bg-white ${props.modalTitle === "Privileges" ? "privileges-popup" : ""
                  }`}
              >
                <h5 className="modal-title mx-5">{props.modalHeaderText}</h5>
              </div>
              <div className="modal-footer bg-white border-top">
                {props.actions &&
                  props.actions.map((value: any, i: number) => (
                    <div className="i">
                      <button
                        type="button" className={`rounded border-0 ${value.type === "Success" ? "custom-bg-btn px-4 text-white py-1 me-2" : "custom-cancel-btn px-3 py-1"}`}
                        onClick={handleModalConfirmation}
                      >
                        {value.label}
                      </button>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomModel;
