import { DataTableCol } from "../../../components/data-table/types";

export const InvitationDataGridCols: DataTableCol[] = [
    {
        title: 'Company Name',
        control: 'company_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'email_id',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Phone Number',
        control: 'contact',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];