import { DataTableCol } from "../../../components/data-table/types";

export const OrganizationDataGridCols: DataTableCol[] = [
    {
        title: 'Business Unit Name',
        control: 'business_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Business Head',
        control: 'head_of_business',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Phone Number',
        control: 'mobile_number',
        sortable: true,
        canShowColumn: true
    }];